import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';

Amplify.configure({
    Auth: {
        Cognito: {
            allowGuestAccess: true,
            identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
        },
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
